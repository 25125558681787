var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("News")]),_c('a-button',{staticClass:"btn btn-outline-primary ml-4",on:{"click":_vm.connector}},[_vm._v("Tambah")])],1),_c('a-table',{attrs:{"loading":_vm.tabloading,"scroll":{ x: 0, y: 400 },"columns":_vm.columns,"dataSource":_vm.data,"pagination":{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        },"size":"small"},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                .toString()
                .split(
                  new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[(
                column.dataIndex === 'img_url' && record.img_url.length > 0
              )?_c('div',[_c('strong',[_vm._v(_vm._s(text))]),_c('br'),_c('img',{staticClass:"preview",attrs:{"src":text,"alt":"text"},on:{"click":function($event){return _vm.lihatgambar(record)}}})]):_c('label',[_vm._v(_vm._s(text))])]]}},{key:"jenis",fn:function(text){return _c('div',{},[(text === 'I')?_c('strong',[_vm._v(_vm._s(text === "I" ? "Induk" : "Detail"))]):_c('label',[_vm._v(_vm._s(text === "I" ? "Induk" : "Detail"))])])}},{key:"name",fn:function(text){return _c('a',{attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(text))])}},{key:"tags",fn:function(tags){return _c('span',{},_vm._l((tags),function(tag){return _c('a-tag',{key:tag,attrs:{"color":"blue"}},[_vm._v(_vm._s(tag))])}),1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{staticClass:"text-warning",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.sendToEdit(record)}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticClass:"text-danger",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v(" Kode")],1)])],2)],1),_c('zoomgambar',{ref:"zoomgambar"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }