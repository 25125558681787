<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>News</strong>
          <a-button class="btn btn-outline-primary ml-4" @click="connector"
            >Tambah</a-button
          >
        </template>
        <a-table
          :loading="tabloading"
          :scroll="{ x: 0, y: 400 }"
          :columns="columns"
          :dataSource="data"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
          size="small"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          ></a-icon>
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <div
                v-if="
                  column.dataIndex === 'img_url' && record.img_url.length > 0
                "
              >
                <strong>{{ text }}</strong
                ><br />
                <img
                  :src="text"
                  alt="text"
                  class="preview"
                  @click="lihatgambar(record)"
                />
              </div>
              <label v-else>{{ text }}</label>
            </template>
          </template>
          <!-- <div slot='keterangan' slot-scope="text" v-html="text"></div> -->
          <div slot="jenis" slot-scope="text">
            <strong v-if="text === 'I'">{{
              text === "I" ? "Induk" : "Detail"
            }}</strong>
            <label v-else>{{ text === "I" ? "Induk" : "Detail" }}</label>
          </div>
          <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
          <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
          <span slot="tags" slot-scope="tags">
            <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <a
              href="javascript:void(0)"
              class="text-warning"
              @click="sendToEdit(record)"
            >
              <a-icon type="edit"></a-icon>
            </a>
            <a-divider type="vertical" />
            <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
              <a-icon type="delete"></a-icon>
            </a>
            <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
          </span>
        </a-table>
      </a-card>
    </div>
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import zoomgambar from '@/components/lou/zoomgambar.vue'
import { Modal } from 'ant-design-vue'

import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
    zoomgambar,
  },
  created() {
    this.getAllData()
  },
  data() {
    return {
      searchText: '',
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          // fixed: 'left',
          width: 30,
        },
        {
          title: 'Tittle',
          dataIndex: 'tittle',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.tittle
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Content',
          dataIndex: 'content',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.content
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Img Url',
          dataIndex: 'img_url',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.img_url
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      data: [],
      tabloading: false,
    }
  },
  methods: {
    moment,
    async getAllData() {
      var res = await lou.customUrlGet2('news')
      if (res) {
        this.data = res.data
      }
    },
    connector() {
      localStorage.setItem(
        'mobileadminnewsformdata',
        JSON.stringify({ id: '' }),
      )
      this.$router.push('/mobileadmin/news/form')
    },
    sendToEdit(edata) {
      // this.$refs.myownmodal.showModal('Update', edata)
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem('mobileadminnewsformdata', JSON.stringify(edata))
      this.$router.push('/mobileadmin/news/form')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          if (deldata.img_url.includes('firebasestorage')) {
            var name = deldata.img_name
            console.log('deldata.img_name', deldata.img_name)
            var desertRef = firebase.storage().ref(deldata.img_name.indexOf('.png') ? 'mobileadmin/news/' + name + '.jpg' : 'mobileadmin/news/' + name + '.png')
            // Delete the file
            desertRef.delete().then(async () => {
              // File deleted successfully
            }).catch((error) => {
              // Uh-oh, an error occurred!
              console.log('error', error)
            })
          }
          // var response = await lou.customUrlDelete2(
          //   'news/' + deldata.id, true, false,
          // )
          // if (response) {
          //   // Delete firebase file
          //   this.getAllData()
          // }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    lihatgambar(record) {
      this.$refs.zoomgambar.lihatgambar(record, 'img_url')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>
img.preview {
  width: 50px;
  height: 50px;
}
/* Style the Image Used to Trigger the Modal */
img.preview {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

img.preview:hover {
  opacity: 0.7;
}
</style>
