<template>
  <!-- The Modal -->
  <div
    :style="mymodal !== true ? 'display: none' : ''"
    class="modalpreviewImageSlur"
    :key="componentKey"
  >
    <!-- The Close Button -->
    <span class="closekhusus" @click="mymodal = false">&times;</span>

    <!-- Modal Content (The Image) -->
    <img class="modal-content-khusus" ref="previewImages" :src="modalImgUrl" />

    <!-- Modal Caption (Image Text) -->
    <div id="captionkhusus" ref="captionkhusus">
      <strong>{{ modalCaption }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mymodal: false,
      modalImgUrl: null,
      modalCaption: '',
      componentKey: 0,
    }
  },
  methods: {
    lihatgambar(record, key = '', caption = '') {
      this.mymodal = true
      this.modalImgUrl = key !== '' ? record[key] : record.imageUrl
      this.modalCaption = caption !== '' ? caption : record.caption
    },
  },
}
</script>

<style>
.modalpreviewImageSlur {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.modal-content-khusus {
  margin: auto;
  display: block;
  max-height: 1280px;
  width: 100%;
  max-width: 700px;
}

/* Caption of Modal Image (Image Text) - Same Width as the Image */
#captionkhusus {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation - Zoom in the Modal */
.modal-content-khusus,
#captionkhusus {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.closekhusus {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.closekhusus:hover,
.closekhusus:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content-khusus {
    width: 100%;
  }
}
</style>
